.certificado {
  background-color: white;
  width:70%;
  height: auto;
  display:block;
  font-family: sans-serif!important;
}

.header-cert{
  display:flex;
  align-items: center;
  padding: 1rem;
  width:100%
}

.datos-perfil{
  width:40%;
  display:flex;
  align-items: center;
}

.datos-perfil > h1{
  margin-left:10%;
}

.foto-perfil{
  border-radius: 50%;
}

.img-perfil{
  width: 100px!important;
  height: 100px!important;
  font-size:50px!important;
  margin-left:30%;
  background-color: #3498db!important;
}

.filete{
  border-right: 1px solid #ccc;
  display:flex;
  width: 40%;
  height:162px;
}

.puntaje{
  width:20%;
}

.numeros{
  font-weight: bolder;
  font-size: 24px;
}

.row{
  padding: 1rem 0;
}

.fil {
  border-bottom: 1px solid #3498db;
}

.fil-certificado {
  height:30px;
  border-bottom: 1px solid #ccc;
}

.contenido{
  min-height:60vh;
  padding: 1rem 0;
  margin-left:3rem;
  margin-right:3rem;
}

.pending{
  padding-top:1rem;
}

.modulo-pendiente{
  color: #333;
  font-size:27px;
}

.bajada-modulo{
  color: #979595;
  font-size:25px;
}

.titulo-certificado{
  margin-top:1rem;
}

.bloque-certificado{
  margin-top:3rem;
}

.box-shadow{
  box-shadow: 3px 3px 9px #ccc;
}

.boton-certificado{
  background-color: #3498db;
  border:0;
}

.certificate-img:hover{
  opacity: 0.5;
}