@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');

body {
  max-width: 100vw;
  background-color: #17181a;
}
body * {
  font-family: 'Roboto','Heebo',Arial,sans-serif;
  margin: 0;
  padding: 0;
}
.center {
  width: 94vw;
  height: 94vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grow-loader {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: #98128D;
  animation: pulse 1500ms infinite;
  margin: 1rem;
}

@keyframes pulse {
  0%{
    box-shadow: #98128D 0 0 0;
  }
  75%{
    box-shadow: #98128D00 0 0 0 24px;
  }
}


#player {
  margin: 0.2rem auto;
  min-width: 98vw;
  height: 65vh;
  z-index: -10;
}

@media (max-width: 895px) {
  #player {
    height: 55vh;
  }
}

.layer_css {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.2rem;
  margin: 0.2rem auto;
  min-width: 100vw;
  height: 70vh;
  border-radius: 5px;
  z-index: 100;
  cursor: pointer;
}

#video_desc {
  padding: 2rem;
  width: 30vw;
  background-color: #383b3f;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  display: flex;
  flex-direction: column;
}
.desc-title, .desc-body, .desc-msg {
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

#video_desc h1, p {
  color: white;
  font-weight: 300;
}
.wrapper {
  padding: 2.2rem 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  justify-content: space-between;
  z-index: -100;
  background-color: #17181a;
}


h3 {
  margin: 0 2rem;
  font-weight: 200;
}

main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

canvas {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

#card {
  margin: 0.2rem;
  width: 100%;
  height: 65vh;
}
@media (max-width: 900px) {
  #player {
    width: 100%;
    min-height: 45vh;
    margin: 0.5rem 0 0 0;
  }
  .layer_css {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 45vh;
    height: 46vh;
    margin: 0.5rem 0 0 0;
  }
  .wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  #card {
    margin: 0.5rem 0 0 0;
    height: 100%;
  }
}

.ripple {
  animation: ripple 2s linear infinite;
}
a {
  text-decoration: none;
  color: #fff
}
a:hover {
  color: #fff;
}

.linkNavigate{
  color: black;
}

.linkNavigate:hover {
  color: rgb(52, 152, 219);
}

